@import './variables.scss';

.modal {
  background-color: white;
  height: 100%;
  padding: 2rem;
}

.mg-b-1 {
  margin-bottom: 1rem !important;
}

.DraftEditor-editorContainer {
  max-height: 200px !important;
}

.css-dmmspl-MuiFormGroup-root {
  display: flex;
  flex-direction: row !important;
  gap: 0.5rem;
}

.css-1h7anqn {
  display: flex;
  flex-direction: row !important;
  gap: 0.5rem;
}
