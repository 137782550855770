@import '../variables.scss';

.thankyou {
    padding: 1rem;
    text-align: center;
    h1 {
        color: $secondary;
        font-size: 24px;
    }
    .success {
        color: green;
        font-size: 32px;
        font-weight: bold;
    }
    form {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex-flow: column !important;
        text-align: justify;
        gap: 1rem;
    }
    .buttonGroup{
        align-self: flex-end;
        display: flex;
        gap: 1rem;
    }

    .error {
        color: red;
        font-size: 16px;
        font-weight: bold;
    }
}