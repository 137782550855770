@import '../variables.scss';

.headerForm {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border: 1px dashed;
    padding: 1rem;
    border-radius: 8px;
    .submitButton {
        align-self: flex-end;
    }
}